import { ActionIcon, Badge, Box, BoxProps, Divider, Group, Paper, Stack, Text, Title, Tooltip } from "@mantine/core";
import { ReactNode } from "@tanstack/react-router";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { titlecase } from "shared/utils/str";
import { getRelativeTimeString } from "shared/utils/temporal";
import { MessageSeverity, MessageStatus } from "src/gql/graphql";
import { match } from "ts-pattern";

type MessageDetailCardProps = {
    title: string;
    unread?: boolean;
    timestamp?: Date | string;
    severity: MessageSeverity;
    body: string;
    status?: MessageStatus;
    action?: {
        icon: ReactNode;
        label: string;
        onClick: (e: React.MouseEvent) => unknown;
    } & BoxProps;
};

export const MessageDetailCard = ({ title, unread, timestamp, status, severity, body, action, ...rest }: MessageDetailCardProps) => {
    const isMobile = useIsMobile();
    const { icon, label, onClick, ...actionRest } = action ?? {};

    return (
        <Box w="100%" {...rest}>
            <Paper shadow="md" radius="md" px="sm" py="md" w="100%">
                <Group justify="space-between" wrap="nowrap" align="flex-start">
                    <Title order={3} c={!unread ? "dimmed" : undefined}>
                        {title}
                    </Title>
                    <Group wrap="nowrap">
                        {status && (
                            <Badge
                                variant="light"
                                miw={"max-content"}
                                color={match(status)
                                    .with(MessageStatus.Expired, () => "red")
                                    .with(MessageStatus.Published, () => "green")
                                    .with(MessageStatus.Scheduled, () => "blue")
                                    .otherwise(() => undefined)}
                            >
                                {match(status)
                                    .with(MessageStatus.Expired, () => "Expired")
                                    .with(MessageStatus.Published, () => "Active")
                                    .with(MessageStatus.Scheduled, () => "Scheduled")
                                    .otherwise(() => undefined)}
                            </Badge>
                        )}
                        {!isMobile && !!action && (
                            <Tooltip label={label} position="bottom">
                                <ActionIcon
                                    variant="subtle"
                                    {...actionRest}
                                    onClick={(e) => {
                                        onClick?.(e);
                                        e.stopPropagation();
                                    }}
                                >
                                    {icon}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </Group>
                </Group>

                <Stack gap={"xs"}>
                    <Text size="sm" c={!unread ? "dimmed" : undefined}>
                        {`${titlecase(severity.toLowerCase())} ${!!timestamp ? "| " + getRelativeTimeString(typeof timestamp == "string" ? new Date(+timestamp) : timestamp) : ""}`}
                    </Text>
                    <Text lineClamp={4} c={!unread ? "dimmed" : undefined}>
                        {body}
                    </Text>
                </Stack>

                {isMobile && !!action && (
                    <>
                        <Divider mx="-sm" mt="sm" />
                        <Group justify="flex-end" mt="sm">
                            <Tooltip label={label}>
                                <ActionIcon
                                    variant="subtle"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClick?.(e);
                                    }}
                                    {...actionRest}
                                >
                                    {icon}
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                    </>
                )}
            </Paper>
        </Box>
    );
};
