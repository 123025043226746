import { Box } from "@mantine/core";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { match } from "ts-pattern";

function DevRibbon(props: { pos: "top" | "bottom" }) {
    const positioning = match(props.pos)
        .with("top", () => ({ top: 0 }))
        .with("bottom", () => ({ bottom: 0 }))
        .exhaustive();

    return (
        <Box
            style={{
                position: "fixed",
                left: 0,
                width: "100%",
                height: "8px",
                background: "repeating-linear-gradient(45deg, yellow, yellow 10px, black 10px, black 20px)",
                zIndex: 9999,
                ...positioning,
            }}
        />
    );
}

const Layout = () => {
    return (
        <>
            <DevRibbon pos="top" />
            <Outlet />
            <DevRibbon pos="bottom" />
        </>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/_dev")({
    beforeLoad: () => {
        if (import.meta.env.VITE_ENVIRONMENT === "prod") throw redirect({ to: "/" });
    },
    component: Layout,
});
