import {
    ActionIcon,
    Box,
    Button,
    Checkbox,
    Combobox,
    Group,
    Input,
    Modal,
    NavLink,
    Pill,
    PillsInput,
    Skeleton,
    Stack,
    Text,
    useCombobox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { useLoaderData } from "@tanstack/react-router";
import { ReactNode, useEffect, useState } from "react";
import { GroupInfo } from "routes/_auth/organization/groups";
import { userGroupKeys } from "shared/api/queryKeys";
import { useGraphqlQuery } from "shared/hooks/useGraphql";
import { useUserStore } from "shared/stores/oidc";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";

export type GroupParent = Pick<GroupInfo, "id" | "name">;

export const GroupTreeNode = ({
    group,
    expandedNodes,
    checkboxOnClick,
    chevronOnClick,
    isDisabledPredicate,
    shouldRedirect,
    selectedCheckboxes,
    skipNodePredicate,
    renderLabel,
}: {
    group: GroupInfo;
    expandedNodes: string[];
    shouldRedirect?: boolean;
    chevronOnClick?: (node: GroupInfo) => void;
    checkboxOnClick?: (node: GroupInfo) => void;
    selectedCheckboxes?: string[];
    isDisabledPredicate?: (group: GroupInfo) => boolean;
    skipNodePredicate?: (group: GroupInfo) => boolean;
    renderLabel: (group: GroupInfo) => ReactNode;
}) => {
    const [fetchChildren, setFetchChildren] = useState(false);
    const { data, isLoading } = useGraphqlQuery(userGroupKeys.queries(), groupQuery, { groupId: group.id }, fetchChildren);

    const children = (!!data ? data.group.children : group.children)?.filter((c) => !skipNodePredicate?.(c));
    const opened = expandedNodes.includes(group.id ?? "");

    const expandButton: ReactNode = (
        <ActionIcon variant="subtle" color="var(--mantine-color-text)">
            <IconChevronRight
                opacity={isDisabledPredicate?.(group) ? 0.4 : undefined}
                className={expandedNodes.includes(group.id ?? "") ? "expandChevron" : "collapseChevron"}
                onClick={() => {
                    if (children?.some((c) => c.children === undefined)) setFetchChildren(true);
                    chevronOnClick?.(group);
                }}
            />
        </ActionIcon>
    );

    return (
        <Stack gap={0} w="min-content" style={{ display: "inline-flex" }}>
            <Group w="min-content" gap={0} p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                {!!checkboxOnClick && !!selectedCheckboxes && (
                    <Checkbox
                        checked={selectedCheckboxes.includes(group.id)}
                        onChange={() => void checkboxOnClick(group)}
                        pr="sm"
                        disabled={isDisabledPredicate?.(group)}
                    />
                )}
                {children?.length !== 0 && expandButton}
                {renderLabel(group)}
            </Group>
            <Stack gap={0} style={{ display: opened ? undefined : "none" }}>
                {isLoading ? (
                    <Skeleton>
                        <NavLink>Placeholder name</NavLink>
                    </Skeleton>
                ) : (
                    <Box
                        w="fit-content"
                        style={{
                            borderLeft: "var(--mantine-spacing-md) solid transparent",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {children?.map((c) => (
                            <GroupTreeNode
                                key={c.id + c.children?.map((c) => c.id).join()}
                                group={c}
                                expandedNodes={expandedNodes}
                                chevronOnClick={chevronOnClick}
                                shouldRedirect={shouldRedirect}
                                skipNodePredicate={skipNodePredicate}
                                checkboxOnClick={checkboxOnClick}
                                isDisabledPredicate={isDisabledPredicate}
                                selectedCheckboxes={selectedCheckboxes}
                                renderLabel={renderLabel}
                            />
                        ))}
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

export const GroupTreeModal = ({
    group,
    disabled,
    values,
    onChange,
}: {
    group: GroupInfo;
    values: GroupParent[];
    onChange: (parents: GroupParent[]) => void;
    disabled?: boolean;
}) => {
    const { rootGroup } = useLoaderData({ from: "/_auth/organization/groups" });
    const combobox = useCombobox();
    const [opened, { open, close }] = useDisclosure();
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<GroupParent[]>([]);

    const { checkPermission } = useUserStore();
    function isDisabledPredicate(g: GroupInfo) {
        return !checkPermission(Permission.GroupSubgroupManage, g.id) || !!(group.synchronized && g.synchronized);
    }

    useEffect(() => {
        setSelectedCheckboxes(values);
    }, [values]);

    return (
        <>
            <Modal opened={opened} onClose={close} title="Select Groups" centered>
                <Stack>
                    <Box mah="75%">
                        <GroupTreeNode
                            group={rootGroup}
                            expandedNodes={expandedNodes}
                            chevronOnClick={(group) => {
                                if (expandedNodes.includes(group.id)) setExpandedNodes(expandedNodes.filter((g) => g != group.id));
                                else setExpandedNodes(expandedNodes.concat(group.id));
                            }}
                            checkboxOnClick={(node: GroupParent) =>
                                setSelectedCheckboxes(
                                    selectedCheckboxes.some((n) => n.id == node.id)
                                        ? selectedCheckboxes.filter((n) => n.id != node.id)
                                        : selectedCheckboxes.concat(node),
                                )
                            }
                            selectedCheckboxes={selectedCheckboxes.map((n) => n.id)}
                            isDisabledPredicate={isDisabledPredicate}
                            skipNodePredicate={(g) => g.id == group.id}
                            renderLabel={(g) => <Text opacity={isDisabledPredicate(g) ? 0.4 : undefined}>{g.name}</Text>}
                        />
                    </Box>
                    <Group>
                        <Button
                            variant="outline"
                            color="var(--mantine-color-text)"
                            style={{ flexGrow: 1 }}
                            onClick={() => {
                                close();
                                setSelectedCheckboxes(values);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ flexGrow: 1 }}
                            onClick={() => {
                                onChange(selectedCheckboxes);
                                close();
                            }}
                        >
                            Continue
                        </Button>
                    </Group>
                </Stack>
            </Modal>
            <Combobox store={combobox}>
                <Combobox.DropdownTarget>
                    <PillsInput
                        pointer
                        onClick={!disabled && group.id != rootGroup.id ? open : undefined}
                        disabled={disabled || group.id == rootGroup.id}
                    >
                        <Pill.Group>
                            {values.length > 0 ? (
                                values.map((g: GroupParent) => (
                                    <Pill
                                        key={g.id}
                                        withRemoveButton
                                        disabled={disabled}
                                        onRemove={() => void onChange(values.filter((x: GroupParent) => x.id != g.id))}
                                    >
                                        {g.name}
                                    </Pill>
                                ))
                            ) : group.id == rootGroup.id ? (
                                <Text fs="italic">This group cannot have any parents</Text>
                            ) : (
                                <Input.Placeholder>Pick one or more values</Input.Placeholder>
                            )}

                            <Combobox.EventsTarget>
                                <PillsInput.Field
                                    type="hidden"
                                    onBlur={() => combobox.closeDropdown()}
                                    onKeyDown={(event) => {
                                        if (event.key === "Backspace") {
                                            event.preventDefault();
                                            onChange(values.slice(0, -1));
                                        }
                                    }}
                                />
                            </Combobox.EventsTarget>
                        </Pill.Group>
                    </PillsInput>
                </Combobox.DropdownTarget>
            </Combobox>
        </>
    );
};

const groupQuery = graphql(`
    query GroupTreeChildGroup($groupId: UUID!) {
        group(id: $groupId) {
            id
            name
            internalJoinable
            synchronized
            members {
                id
                firstName
                lastName
            }
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                }
                children {
                    id
                    name
                    internalJoinable
                    synchronized
                    members {
                        id
                        firstName
                        lastName
                    }
                    children {
                        id
                        name
                        internalJoinable
                        synchronized
                        members {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
    }
`);
