import { createRouter, redirect } from "@tanstack/react-router";
import { routeTree } from "routeTree.gen";
import { useUserStore } from "shared/stores/oidc";

export const router = createRouter({
    routeTree,
    defaultPreload: "intent",
    defaultStaleTime: 15 * 60 * 60 * 1000, // 15 minutes
    context: {
        auth: undefined!,
        assertPermission(permission, id) {
            const { checkPermission } = useUserStore.getState();
            if (!checkPermission(permission, id)) throw redirect({ to: "/" });
        },
        assertPermissions(opts) {
            const { checkPermission } = useUserStore.getState();

            opts.forEach((args) => {
                if (Array.isArray(args) && !checkPermission(...args)) throw redirect({ to: "/" });
                else if (!Array.isArray(args) && !checkPermission(args)) throw redirect({ to: "/" });
            });
        },
    },
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
