import { Accordion, Group, Title } from "@mantine/core";
import { ReactNode } from "@tanstack/react-router";
import { createContext, useContext, useEffect, useId, useState } from "react";

/** @private */
const SectionCtx = createContext({ announceId: (_id: string) => {} });

/** @private  */
function useSectionId() {
    const id = useId();
    const { announceId } = useContext(SectionCtx);

    useEffect(() => {
        announceId(id);
    }, [id]);

    return { id };
}

type SectionProviderProps = { children: ReactNode };
export const SectionProvider = ({ children }: SectionProviderProps) => {
    const [ids, setIds] = useState<string[]>([]);

    // we want the *initial* animation to have a duration of 0 to appear instant,
    // however since we are collectings the child ids after the first render
    // we need to trace when the user "collapsed" a item the first time,
    // at which point we stop forcing the transtionDuration to be 0
    const [userDidInteract, setUserDidInteract] = useState(false);

    return (
        <SectionCtx.Provider value={{ announceId: (id) => setIds((prev) => prev.concat([id])) }}>
            <Accordion
                multiple
                value={ids}
                onChange={(next) => {
                    setUserDidInteract(true);
                    setIds(next);
                }}
                transitionDuration={!userDidInteract ? 0 : undefined}
            >
                {children}
            </Accordion>
        </SectionCtx.Provider>
    );
};

type SectionProps = { label?: ReactNode; affix?: ReactNode; children: ReactNode };

export const Section = ({ label, affix, children }: SectionProps) => {
    const { id } = useSectionId();

    return (
        <Accordion.Item value={id}>
            <Accordion.Control>
                <Group>
                    {typeof label == "string" ? <Title order={4}>{label}</Title> : label}
                    {affix}
                </Group>
            </Accordion.Control>
            <Accordion.Panel>{children}</Accordion.Panel>
        </Accordion.Item>
    );
};
