import { Text } from "@mantine/core";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { MessageDetailCard } from "shared/components/message/MessageDetailCard";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const messages = pages.flat();

    return (
        <Content breadcrumbs>
            {messages.length == 0 && (
                <NoMessagesFigure>
                    <Text ta="center" c="dimmed">
                        Looks like you haven't sent any messages yet!
                    </Text>
                </NoMessagesFigure>
            )}

            {messages.map((message) => (
                <MessageDetailCard
                    unread
                    key={message.id}
                    status={message.status}
                    title={message.subject}
                    timestamp={message.publishedAt ?? undefined}
                    severity={message.severity}
                    body={message.body}
                />
            ))}

            <LoadMoreTrigger query={query} />
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query SentMessages($limit: Int!, $offset: Int!) {
            sent(limit: $limit, offset: $offset) {
                id
                subject
                body
                status
                severity
                publishedAt
            }
        }
    `),
    queryKey: ["messages", "sent"],
    select: ({ sent }) => sent,
});

/**
 * @public
 */
export const Route = createFileRoute("/_auth/messages/sent")({
    shouldReload: false,
    component: Component,
    beforeLoad: ({ context: { assertPermissions } }) => assertPermissions([Permission.GroupMessageSendFrom, Permission.GroupMessageSendTo]),
    loader: () => queryClient.prefetchInfiniteQuery(query),
    meta: () => [{ title: "Sent" }],
});
