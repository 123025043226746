import { Menu, Text } from "@mantine/core";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import { FloatingActionButton } from "shared/components/global/FloatingActionButton/FloatingActionButton";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { MessageDetailCard } from "shared/components/message/MessageDetailCard";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const messages = pages.flat();

    return (
        <Content breadcrumbs>
            {messages.length == 0 && (
                <NoMessagesFigure>
                    <Text ta={"center"} c="dimmed">
                        Looks like you don't have any messages yet!
                    </Text>
                </NoMessagesFigure>
            )}

            {messages.map((message) => (
                <Link to="/messages/$id" params={{ id: message.id }} key={message.id}>
                    <MessageDetailCard
                        title={message.subject}
                        severity={message.severity}
                        status={message.status}
                        timestamp={new Date(Number.parseInt(message.publishedAt ?? ""))}
                        body={message.body}
                        unread={!message.about?.didAck}
                    />
                </Link>
            ))}

            <LoadMoreTrigger query={query} />

            <FloatingActionButton>
                <Link to="/messages/compose">
                    <Menu.Item>New Message</Menu.Item>
                </Link>
            </FloatingActionButton>
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query InboxMessages($limit: Int!, $offset: Int!) {
            inbox(limit: $limit, offset: $offset) {
                id
                body
                status
                subject
                publishedAt
                about {
                    didAck
                }
                severity
            }
        }
    `),
    queryKey: ["messages", "inbox"],
    select: ({ inbox }) => inbox,
});

/** @public */
export const Route = createFileRoute("/_auth/messages/inbox")({
    component: Component,
    shouldReload: false,
    loader: () => queryClient.prefetchInfiniteQuery(query),
    meta: () => [
        {
            title: "Inbox",
        },
    ],
});
