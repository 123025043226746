import { generateColors } from "@mantine/colors-generator";
import { createTheme, CSSVariablesResolver, DEFAULT_THEME, mergeMantineTheme, rem } from "@mantine/core";

const primaryColor: string = import.meta.env.VITE_ORG_PRIMARY_COLOR ?? "red";
const primaryHex: string = import.meta.env.VITE_ORG_PRIMARY_HEX ?? "#8F0808";

const themeOverride = createTheme({
    primaryColor,
    primaryShade: {
        light: 8,
        dark: 7,
    },
    fontFamily: "Open Sans",
    colors: {
        [primaryColor]: generateColors(primaryHex),
    },
    spacing: {
        xs: rem(8),
    },
});

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);

export const cssVariablesResolver: CSSVariablesResolver = () => ({
    variables: {},
    light: {},
    dark: {},
});
