import { WebStorageStateStore } from "oidc-client-ts";
import { logger } from "shared/utils/logger";
import { Permission, UserQuery } from "src/gql/graphql";
import { z } from "zod";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type OidcStore = {
    config: {
        authority: string;
        client_id: string;
        redirect_uri: string;
        post_logout_redirect_uri: string;
        scope: string;
        userStore: WebStorageStateStore;
    };
    setRealm: (realm: string) => void;
    getAccessToken: () => string | undefined;
};

const [subdomain] = window.location.hostname.split(".");
const realm = subdomain == "localhost" ? import.meta.env["VITE_FALLBACK_REALM"] : subdomain;

const userStoreDefault = {
    config: {
        authority: `${import.meta.env["VITE_KC_BASE_URL"]}/realms/${realm}`,
        client_id: "emns-web",
        redirect_uri: `${window.location.origin}/account/callback`,
        post_logout_redirect_uri: `${window.location.origin}/login`,
        scope: "openid profile email",
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    },
};

logger.log("Using OIDC config", userStoreDefault.config);

export const useOIDCStore = create<OidcStore>()(
    devtools(
        // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#options
        persist(
            immer((set, get) => {
                return {
                    ...userStoreDefault,
                    setRealm: (realm: string) => {
                        set((state) => {
                            state.config.authority = `${import.meta.env.VITE_KC_BASE_URL}/realms/${realm}`;
                        });
                    },
                    getAccessToken() {
                        const {
                            config: { authority, client_id },
                        } = get();
                        const raw = localStorage.getItem(`oidc.user:${authority}:${client_id}`);
                        if (raw == null) return undefined;

                        const parsed = z.object({ access_token: z.string() }).safeParse(JSON.parse(raw));
                        if (parsed.success) return parsed.data.access_token;
                        else return undefined;
                    },
                };
            }),
            {
                name: "emns.store.oidc",
                storage: createJSONStorage(() => localStorage),
                partialize: (store) => ({
                    config: store.config,
                }),
            },
        ),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "Auth" },
    ),
);

type UserStore = {
    user: UserQuery["user"];
    setUser: (user: UserQuery["user"]) => void;
    clearUser: () => void;
    checkPermissions: (opts: [permission: Permission, withId?: string][]) => boolean;
    checkPermission: (permission: Permission, withId?: string) => boolean;
};

const defaultUser: UserQuery["user"] = {
    groups: [],
    id: "",
    lastName: "",
    roles: [],
};

export const useUserStore = create<UserStore>((set, get) => ({
    user: defaultUser,
    setUser: (user) => set({ user }),
    clearUser: () => set({ user: defaultUser }),
    /*
     * Usage: if no `withId` is specified, checks if the user has at least one instance of a `permission` on
     * any role.
     *
     * If a `withId` __is__ specified, the permission will only match if the role operates on an entity with
     * a matching ID, ie a group or topic.
     */
    checkPermission: (permission, withId) =>
        !!withId
            ? !!get().user.roles.find(
                  (r) => (r.group?.id === withId || r.topic?.id === withId) && r.role.permissions.some((p) => p.id === permission),
              )
            : !!get().user.roles.find((r) => r.role.permissions.some((p) => p.id === permission)),
    checkPermissions(opts) {
        return opts.reduce((isPassing, args) => isPassing && this.checkPermission(...args), true);
    },
}));
