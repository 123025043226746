import { IconChartPie2, IconHome, IconInbox, IconMapPin } from "@tabler/icons-react";
import { ParseRoute } from "@tanstack/react-router";
import { routeTree } from "routeTree.gen";
import { Permission } from "src/gql/graphql";

type SidebarLinkData = {
    icon: typeof IconHome;
    label: string;
    to: ParseRoute<typeof routeTree>["fullPath"];
    permission?: Permission;
    permissionId?: string;
};

// Invariant: if you add an item to sideBarLinkData, you must add to sidebarSubLinkData
// in the same position as well. `data` should be an empty array if no sub-links exist

export const sidebarLinkData: SidebarLinkData[] = [
    { icon: IconHome, label: "Home", to: "/home" },
    { icon: IconInbox, label: "Messages", to: "/messages" },
    { icon: IconMapPin, label: "Map", to: "/map" },
    { icon: IconChartPie2, label: "Chart", to: "/organization" },
];

export const sidebarSubLinkData: { header: string; data: Omit<SidebarLinkData, "icon">[] }[] = [
    {
        header: "Home",
        data: [],
    },
    {
        header: "Messages",
        data: [
            { label: "Inbox", to: "/messages/inbox" },
            {
                label: "Drafts",
                to: "/messages/drafts",
            },
            {
                label: "Scheduled",
                to: "/messages/scheduled",
            },
            {
                label: "Sent",
                to: "/messages/sent",
            },
        ],
    },
    { header: "Map", data: [] },
    {
        header: "Organization",
        data: [
            { label: "Audit Logs", to: "/organization/auditLogs" },
            { label: "Groups", to: "/organization/groups", permission: Permission.GroupSubgroupManage },
            { label: "Reports", to: "/" },
            { label: "Roles", to: "/" },
            { label: "Templates", to: "/" },
            { label: "Topics", to: "/" },
            { label: "Users", to: "/" },
        ],
    },
];
