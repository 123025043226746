import { Center, CenterProps } from "@mantine/core";
import { useIsMobile } from "shared/hooks/useIsMobile";

export const NoMessagesFigure = ({ children, ...rest }: CenterProps) => {
    const isMobile = useIsMobile();

    return (
        <Center
            m="auto"
            pb={"md"}
            h="78dvh"
            miw={isMobile ? "90%" : "50%"}
            style={{ flexDirection: "column", justifyContent: "space-between" }}
            {...rest}
        >
            <div />
            <svg viewBox="0 0 290 283" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.8676 42.2118C-3.11471 60.1101 -1.40791 87.5906 12.9614 117.715C27.3151 147.806 54.2356 180.364 90.338 208.229C126.44 236.093 164.843 253.952 197.729 260.32C230.653 266.697 257.86 261.534 271.844 243.636C285.825 225.737 284.118 198.257 269.75 168.133C255.396 138.041 228.474 105.483 192.373 77.6189C156.27 49.7547 117.867 31.8951 84.9807 25.5261C52.0587 19.1503 24.8502 24.3137 10.8676 42.2118Z"
                    stroke="var(--mantine-primary-color-contrast)"
                    stroke-width="0.5"
                />
                <path
                    d="M23.5962 55.2271C23.5962 59.6202 20.0131 63.1816 15.5931 63.1816C11.1732 63.1816 7.59009 59.6202 7.59009 55.2271C7.59009 50.8339 11.1732 47.2725 15.5931 47.2725C20.0131 47.2725 23.5962 50.8339 23.5962 55.2271Z"
                    stroke="var(--mantine-primary-color-light-color)"
                />
                <path
                    d="M286.555 224.545C286.555 228.939 282.972 232.5 278.552 232.5C274.132 232.5 270.549 228.939 270.549 224.545C270.549 220.152 274.132 216.591 278.552 216.591C282.972 216.591 286.555 220.152 286.555 224.545Z"
                    stroke="var(--mantine-primary-color-light-color)"
                />
                <path
                    d="M229.388 51.8182C229.388 56.2114 225.805 59.7727 221.385 59.7727C216.965 59.7727 213.382 56.2114 213.382 51.8182C213.382 47.425 216.965 43.8636 221.385 43.8636C225.805 43.8636 229.388 47.425 229.388 51.8182Z"
                    stroke="var(--mantine-primary-color-light-color)"
                />
                <path
                    d="M103.628 9.77273H97.9111V15.4546C97.9111 16.0796 97.3966 16.5909 96.7678 16.5909C96.139 16.5909 95.6245 16.0796 95.6245 15.4546V9.77273H89.9081C89.2793 9.77273 88.7648 9.26137 88.7648 8.63637C88.7648 8.01137 89.2793 7.50001 89.9081 7.50001H95.6245V1.81819C95.6245 1.19319 96.139 0.681824 96.7678 0.681824C97.3966 0.681824 97.9111 1.19319 97.9111 1.81819V7.50001H103.628C104.256 7.50001 104.771 8.01137 104.771 8.63637C104.771 9.26137 104.256 9.77273 103.628 9.77273Z"
                    fill="var(--mantine-primary-color-9)"
                />
                <path
                    d="M31.5976 212.046H25.8812V217.728C25.8812 218.353 25.3667 218.864 24.7379 218.864C24.1091 218.864 23.5946 218.353 23.5946 217.728V212.046H17.8781C17.2493 212.046 16.7348 211.534 16.7348 210.909C16.7348 210.284 17.2493 209.773 17.8781 209.773H23.5946V204.091C23.5946 203.466 24.1091 202.955 24.7379 202.955C25.3667 202.955 25.8812 203.466 25.8812 204.091V209.773H31.5976C32.2264 209.773 32.7409 210.284 32.7409 210.909C32.7409 211.534 32.2264 212.046 31.5976 212.046Z"
                    fill="var(--mantine-primary-color-9)"
                />
                <g clip-path="url(#clip0_0_1)">
                    <path
                        d="M41.4806 88.6047C41.4806 82.569 43.8927 76.7805 48.1862 72.5127C52.4798 68.2448 58.303 65.8472 64.375 65.8472H224.636C230.708 65.8472 236.531 68.2448 240.824 72.5127C245.118 76.7805 247.53 82.569 247.53 88.6047V202.392C247.53 208.428 245.118 214.216 240.824 218.484C236.531 222.752 230.708 225.15 224.636 225.15H64.375C58.303 225.15 52.4798 222.752 48.1862 218.484C43.8927 214.216 41.4806 208.428 41.4806 202.392V88.6047Z"
                        stroke="var(--mantine-primary-color-filled)"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M41.4806 88.6046L144.505 156.877L247.53 88.6046"
                        stroke="var(--mantine-primary-color-filled)"
                        stroke-width="1"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </svg>

            {children}
        </Center>
    );
};
