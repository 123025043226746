import { createFileRoute } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { graphql } from "src/gql";

const Component = () => {
    return <Content breadcrumbs>Your Mom</Content>;
};

/** @public */
export const Route = createFileRoute("/_auth/messages/$id")({
    component: Component,
    loader: async ({ params: { id } }) =>
        httpPostGraphql(
            graphql(`
                query MessageDetail($id: UUID!) {
                    message(id: $id) {
                        id
                        subject
                        body
                    }
                }
            `),
            { id },
        ),
    meta: ({
        loaderData: {
            message: { subject },
        },
    }) => [
        {
            title: subject,
        },
    ],
});
