import { Title } from "@mantine/core";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { Section, SectionProvider } from "shared/components/global/Section";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const query = (id: string) =>
    queryOptions({
        queryKey: qk("messages", "composer", id),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query ComposeMessageDetail($id: UUID!) {
                        message(id: $id) {
                            id
                            subject
                            body
                        }
                    }
                `),
                { id },
            ),
    });

const Component = () => {
    const { id } = useParams({ from: "/_auth/messages/compose/$id" });
    const {
        data: {
            message: { subject, body },
        },
    } = useSuspenseQuery(query(id));

    return (
        <Content>
            <Title order={3} c="dimmed">
                {"<< message detail screen placeholder >>"}
            </Title>
            <SectionProvider>
                <Section label={"Subject"}>{subject}</Section>
                <Section label={"Body"}>{body}</Section>
            </SectionProvider>
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/messages/compose/$id")({
    loader: ({ params: { id } }) => queryClient.ensureQueryData(query(id)),
    component: Component,
    meta: () => [{ title: "Compose Mesasge" }],
});
