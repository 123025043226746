import { Text, Title, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate, useRouter } from "@tanstack/react-router";
import { motion } from "motion/react";
import { Content } from "shared/components/global/Content";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { MessageDetailCard } from "shared/components/message/MessageDetailCard";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useOptimisticUpdater } from "shared/hooks/useOptimisicUpdater";
import { notify } from "shared/utils/notify";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const { preloadRoute } = useRouter();
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const { updater } = useOptimisticUpdater(query);
    const { mutateAsync: discardDraftAsync } = useGraphqlMutation(
        graphql(`
            mutation DraftsDiscardMesasgeDraft($id: UUID!) {
                discardMessageDraft(id: $id) {
                    id
                }
            }
        `),
    );

    const nav = useNavigate();
    const drafts = pages.flat();

    function handleDeleteDraft(id: string) {
        modals.openConfirmModal({
            centered: true,
            title: <Title order={4}>Confirm Draft Discard</Title>,
            labels: {
                confirm: "Discard",
                cancel: "Cancel",
            },
            children: "Are you sure you want to discard this draft?",
            onConfirm() {
                void discardDraftAsync({ id }).then(() => {
                    updater.delete(id);
                    notify.show.success({
                        message: `Draft Discarded`,
                    });
                });
            },
        });
    }

    return (
        <Content breadcrumbs>
            {drafts.length == 0 && (
                <NoMessagesFigure>
                    <Text ta={"center"} c="dimmed">
                        Looks like you don't have any message Drafts yet
                    </Text>
                </NoMessagesFigure>
            )}
            {drafts.map((draft) => (
                <motion.div layout key={draft.id}>
                    <UnstyledButton
                        w="100%"
                        onMouseEnter={() => {
                            void preloadRoute({ to: "/messages/compose/$id", params: { id: draft.id } });
                        }}
                        onClick={() => {
                            void nav({ to: "/messages/compose/$id", params: { id: draft.id } });
                        }}
                    >
                        <MessageDetailCard
                            unread
                            title={draft.subject}
                            severity={draft.severity}
                            body={draft.body}
                            action={{
                                icon: <IconTrash />,
                                label: "Discard",
                                onClick: () => handleDeleteDraft(draft.id),
                            }}
                        />
                    </UnstyledButton>
                </motion.div>
            ))}

            <LoadMoreTrigger query={query} />
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query DraftMessages($offset: Int!, $limit: Int!) {
            drafts(limit: $limit, offset: $offset) {
                id
                body
                subject
                severity
                status
            }
        }
    `),
    select: ({ drafts }) => drafts,
    queryKey: ["messages", "draft"],
});

/** @public */
export const Route = createFileRoute("/_auth/messages/drafts")({
    shouldReload: false,
    component: Component,
    beforeLoad: ({ context: { assertPermissions } }) => assertPermissions([Permission.GroupMessageSendFrom, Permission.GroupMessageSendTo]),
    loader: () => queryClient.prefetchInfiniteQuery(query),
    meta: () => [
        {
            title: "Drafts",
        },
    ],
});
