import { ActionIcon, Affix, Menu } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { ReactNode, useState } from "react";
import { useIsMobile } from "shared/hooks/useIsMobile";

export const FloatingActionButton = ({ children }: { children: ReactNode }) => {
    const [opened, setOpened] = useState(false);
    const isMobile = useIsMobile();

    return (
        <Affix position={{ bottom: 20 + (isMobile ? 50 : 0), right: 20 }} zIndex={0}>
            <Menu shadow="md" width={200} position="left-end" opened={opened} onChange={setOpened}>
                <Menu.Target>
                    <ActionIcon radius="xl" size={40}>
                        <IconPlus size={20} style={{ transition: "all linear 150ms" }} transform={opened ? "rotate(45)" : undefined} />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>{children}</Menu.Dropdown>
            </Menu>
        </Affix>
    );
};
