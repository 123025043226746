import { createFileRoute, redirect } from "@tanstack/react-router";
import { logoutFunction } from "shared/api/authApi";

/**
 * @public
 */
export const Route = createFileRoute("/logout")({
    beforeLoad: async ({ context, location }) => {
        const auth = await context.auth;

        if (auth.isAuthenticated) await logoutFunction(auth);
        throw redirect({ to: "/login", search: { redirect: location.href } });
    },
});
