import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { infiniteQueryOptions, useInfiniteQuery, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { paginatedChunkSize } from "shared/const";
import type { EmnsQK } from "shared/utils/qk";

// imported to make doclinks resolve properly
void useInfiniteQuery;
void useSuspenseInfiniteQuery;

/**
 * Yields a queryOptions object which may be passed to {@link useInfiniteQuery} or {@link useSuspenseInfiniteQuery} from a graphql doc which auto-injects `offset` and `limit` variables.
 */
export function createOffsetBasedGqlQueryOptions<
    TDocumentData,
    TVariables extends { offset: number; limit: number },
    TQueryKey extends EmnsQK,
    TSelected extends unknown[],
>(opts: {
    queryKey: TQueryKey;
    document: TypedDocumentNode<TDocumentData, TVariables>;
    variables?: Omit<NoInfer<TVariables>, "offset" | "limit">;
    select: (data: NoInfer<TDocumentData>) => TSelected;
}) {
    return infiniteQueryOptions({
        getNextPageParam: (lastPage, allPages, lastOffset) =>
            lastOffset != 0 && allPages.flat().length == lastOffset ? null : lastOffset + lastPage.length,
        getPreviousPageParam: (lastPage, _allPages, lastOffset) => (lastOffset == 0 ? null : lastOffset - lastPage.length),
        initialPageParam: 0,
        initialData: { pages: [] as TSelected[], pageParams: [] },
        queryKey: opts.queryKey,
        queryFn: async ({ pageParam, signal }) => {
            const res = await httpPostGraphql<TDocumentData, TVariables & { offset: number; limit: number }>(
                opts.document,
                { ...(opts.variables ?? {}), offset: pageParam ?? 0, limit: paginatedChunkSize } as TVariables,
                {
                    signal,
                },
            );

            return opts.select(res);
        },
    });
}
