import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import "@mantine/dates/styles.css";
import "src/main.css";

import { ColorSchemeScript, Loader, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { StrictMode, Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { AuthContextProps, AuthProvider, useAuth } from "react-oidc-context";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useOIDCStore } from "shared/stores/oidc";
import { cssVariablesResolver, theme } from "shared/utils/theme";
import { queryClient } from "src/queryClient";
import { router } from "src/router";

dayjs.extend(localizedFormat);

const { promise, resolve } = Promise.withResolvers<AuthContextProps>();

const App = () => {
    const auth = useAuth();
    const isMobile = useIsMobile();

    useEffect(() => {
        if (auth.isLoading) return;
        resolve(auth);
    }, [auth, auth.isLoading]);

    if (auth.isLoading)
        return (
            <div style={{ height: "100dvh", width: "100dvw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Loader />
            </div>
        );

    return (
        <Suspense fallback={<Loader />}>
            <Notifications position={isMobile ? "top-center" : "bottom-right"} />
            <RouterProvider router={router} context={{ auth: promise }} />
        </Suspense>
    );
};

const rootElement = document.getElementById("app")!;

if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <StrictMode>
            <AuthProvider {...useOIDCStore.getState().config}>
                <QueryClientProvider client={queryClient}>
                    <ColorSchemeScript defaultColorScheme="auto" />
                    <MantineProvider defaultColorScheme="auto" cssVariablesResolver={cssVariablesResolver} theme={theme}>
                        <ModalsProvider>
                            <App />
                        </ModalsProvider>
                    </MantineProvider>
                </QueryClientProvider>
            </AuthProvider>
        </StrictMode>,
    );
}
