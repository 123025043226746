import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useMutation, UseMutationResult, useQuery, type UseQueryResult } from "@tanstack/react-query";
import { type ExecutionResult } from "graphql";
import { httpPostGraphql } from "shared/api/httpClient";
import { AbstractedMutationOptions } from "shared/utils/http";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";

export function useGraphqlQuery<T, V>(
    queryKey: string[],
    document: TypedDocumentNode<T, V>,
    variables: V,
    enabled?: boolean,
): UseQueryResult<ExecutionResult<T>["data"]> {
    return useQuery({
        queryKey: [...queryKey, document, variables],
        queryFn: ({ signal }) => httpPostGraphql<T, V>(document, variables, { signal }),
        meta: {
            errorMessage: "Failed to get data",
        },
        enabled,
    });
}

type GraphqlMutationArgs<T, V> = {
    document: TypedDocumentNode<T, V>;
} & AbstractedMutationOptions<ExecutionResult<T>["data"], V>;

export function useGraphqlMutation<T, V>(document: GraphqlMutationArgs<T, V>["document"]): UseMutationResult<T, Error, V, unknown>;
export function useGraphqlMutation<T, V>(args: GraphqlMutationArgs<T, V>): UseMutationResult<T, Error, V, unknown>;
export function useGraphqlMutation<T, V>(args: GraphqlMutationArgs<T, V> | GraphqlMutationArgs<T, V>["document"]) {
    if ("document" in args) {
        const { document, onSuccess, onError, ...rest } = args;
        return useMutation({
            throwOnError: false,
            mutationFn: (variables) => httpPostGraphql<T, V>(document, variables),
            onSuccess: (res, ...rest) => {
                onSuccess?.(res, ...rest);
            },
            onError: (err, ...rest) => {
                onError?.(err, ...rest);
                notify.show.error({ message: "Mutation failed" });
                logger.error(err);
            },
            ...rest,
        });
    } else {
        return useGraphqlMutation({ document: args });
    }
}
