import "routes/_auth/organization/groups.css";

import { ActionIcon, Anchor, Button, Group, Stack, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowLeft, IconList, IconPlus } from "@tabler/icons-react";
import { createFileRoute, Outlet, useNavigate, useRouterState } from "@tanstack/react-router";
import { useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { GroupTreeNode } from "shared/components/groups/Groups";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useUserStore } from "shared/stores/oidc";
import { graphql } from "src/gql";
import { GroupNode, Permission, UserNode } from "src/gql/graphql";

export type GroupInfo = Pick<GroupNode, "id" | "name"> & {
    members: Pick<UserNode, "id" | "firstName" | "lastName">[];
    children?: GroupInfo[];
    parentIds?: GroupInfo[];
    synchronized?: boolean;
};

const GroupsComponent = () => {
    const { rootGroup } = Route.useLoaderData();
    const isMobile = useIsMobile();
    const [opened, { toggle }] = useDisclosure(true);
    const [groupsExpanded, setGroupsExpanded] = useState<string[]>([rootGroup.id]);
    const nav = useNavigate();
    const router = useRouterState();

    const { checkPermission } = useUserStore();
    function isDisabledPredicate(g: GroupInfo) {
        return !checkPermission(Permission.GroupSubgroupManage, g.id);
    }

    function chevronOnClick(group: GroupInfo) {
        if (groupsExpanded.includes(group.id)) setGroupsExpanded(groupsExpanded.filter((g) => g != group.id));
        else setGroupsExpanded(groupsExpanded.concat(group.id));
    }

    function groupOnClick(group: GroupInfo) {
        void nav({ to: `/organization/groups/${group.id}` });
    }

    const Label = (group: GroupInfo) =>
        isDisabledPredicate(group) ? (
            <Text opacity={0.4}>{group.name}</Text>
        ) : (
            <Text onClick={() => groupOnClick?.(group)}>
                <Anchor style={{ color: "var(--mantine-color-text)" }}>{group.name}</Anchor>
            </Text>
        );

    return isMobile ? (
        <Stack p="1rem" h="100%">
            {router.location.pathname.split("/").slice(-1).includes("groups") ? (
                <>
                    <Title order={2}>Groups</Title>
                    <GroupTreeNode
                        group={rootGroup}
                        expandedNodes={groupsExpanded}
                        chevronOnClick={chevronOnClick}
                        isDisabledPredicate={isDisabledPredicate}
                        renderLabel={Label}
                        shouldRedirect
                    />
                </>
            ) : (
                <>
                    <Group>
                        <ActionIcon
                            variant="transparent"
                            color="var(--mantine-color-text)"
                            size="sm"
                            onClick={() => void nav({ to: "/organization/groups" })}
                        >
                            <IconArrowLeft />
                        </ActionIcon>
                        <Title order={2}>Groups</Title>
                    </Group>

                    <Outlet />
                </>
            )}
        </Stack>
    ) : (
        <Stack gap={0} h="100%">
            <Group justify="space-between" w="100%" h="7dvh" p="xs" style={{ borderBottom: "1px solid var(--app-shell-border-color)" }}>
                <Title order={2} fw="normal">
                    Groups
                </Title>
                <Button variant="outline" leftSection={<IconPlus />} color="var(--mantine-color-text)">
                    Create Group
                </Button>
            </Group>
            <Group gap={0} wrap="nowrap" style={{ flexGrow: 1, overflowY: "auto" }}>
                <Stack
                    h="100%"
                    mah="85dvh"
                    w={opened ? "16rem" : "min-content"}
                    style={{ borderRight: "1px solid var(--app-shell-border-color)" }}
                    p="xs"
                >
                    <Group>
                        <ActionIcon variant="transparent" color="var(--mantine-color-text)" onClick={toggle}>
                            {opened ? <IconArrowLeft /> : <IconList />}
                        </ActionIcon>
                    </Group>
                    <Stack display={opened ? undefined : "none"} style={{ overflow: "auto", gap: 0, flexGrow: 1 }}>
                        <GroupTreeNode
                            group={rootGroup}
                            expandedNodes={groupsExpanded}
                            chevronOnClick={chevronOnClick}
                            isDisabledPredicate={isDisabledPredicate}
                            renderLabel={Label}
                            shouldRedirect
                        />
                    </Stack>
                </Stack>
                <Outlet />
            </Group>
        </Stack>
    );
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/groups")({
    component: GroupsComponent,
    loader: async ({ abortController }) => {
        const { rootGroup } = await httpPostGraphql(rootGroupQuery, {}, { signal: abortController.signal });
        return { rootGroup };
    },
    meta: () => [{ title: "Groups" }],
});

export const rootGroupQuery = graphql(`
    query GroupTreeRootGroup {
        rootGroup {
            id
            name
            internalJoinable
            synchronized
            members {
                id
                firstName
                lastName
            }
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                }
                children {
                    id
                    name
                    internalJoinable
                    synchronized
                    members {
                        id
                        firstName
                        lastName
                    }
                    children {
                        id
                        name
                        internalJoinable
                        synchronized
                        members {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
            parents {
                id
                name
            }
        }
    }
`);
