import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AuthContextProps } from "react-oidc-context";
import { AppError } from "shared/components/global/AppError/AppError";
import { NotFound } from "shared/components/global/NotFound/NotFound";
import { Permission } from "src/gql/graphql";

interface AuthRouterContext {
    auth: Promise<AuthContextProps>;

    assertPermission: (permission: Permission, id?: string) => void;
    assertPermissions: (opts: (Permission | [permission: Permission, id: string])[]) => void;
}

const Component = () => {
    return (
        <>
            <Outlet />
            {import.meta.env.VITE_ENVIRONMENT !== "prod" && !import.meta.env.VITE_DISABLE_DEVTOOLS && (
                <>
                    <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />
                    <ReactQueryDevtools position="right" initialIsOpen={false} />
                </>
            )}
        </>
    );
};

/** @public */
export const Route = createRootRouteWithContext<AuthRouterContext>()({
    errorComponent: AppError,
    notFoundComponent: NotFound,
    component: Component,
});
