import { QueryKey } from "@tanstack/react-query";
import { useState } from "react";
import { queryClient } from "src/queryClient";

export function useOptimisticUpdater(opts: { queryKey: QueryKey }) {
    const [deletedIds, setDeletedIds] = useState([] as string[]);

    return {
        updater: {
            /**
             * Removes and excludes the given ID from the query data associated with the given query
             */
            delete(id: string) {
                setDeletedIds((ids) => ids.concat([id]));

                queryClient.setQueryData(opts.queryKey, (data: { pages?: { id: string }[][]; pageParams: number[] }) => ({
                    pages: (data.pages ?? []).map((page) => page.filter((it) => !deletedIds.concat([id]).includes(it.id))),
                    pageParams: data.pageParams,
                }));
            },
        },
    };
}
