import { Container, ContainerProps, Stack, StackProps } from "@mantine/core";
import { useBreadcrumbs } from "shared/hooks/useBreadcrumbs";

type ContentProps = {
    /**
     * When set, mounts a responsive breadcrumbs component above the children
     */
    breadcrumbs?: boolean;
    /**
     * Additonal props to pass to the underlying {@link Stack} wrapper
     */
    stack?: StackProps;
} & ContainerProps;

/**
 * A responsive {@link Container} wrapper. This is the default wrapper that should be used for all pages.
 */
export const Content = ({ breadcrumbs, stack, children, ...rest }: ContentProps) => {
    const { Breadcrumbs } = useBreadcrumbs();

    return (
        <Container size={"xl"} {...rest}>
            <Stack {...stack}>
                {breadcrumbs && <Breadcrumbs />}
                {children}
            </Stack>
        </Container>
    );
};
