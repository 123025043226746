import {
    Accordion,
    AccordionControlProps,
    ActionIcon,
    Box,
    Button,
    Center,
    Checkbox,
    Divider,
    Group,
    InputLabel,
    Modal,
    Stack,
    Switch,
    Text,
    TextInput,
    Title,
    Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconDownload, IconEdit, IconPlus, IconX } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, useBlocker, useRouter } from "@tanstack/react-router";
import { ReactNode, useEffect, useRef, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { GroupTreeModal } from "shared/components/groups/Groups";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useUserStore } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { graphql } from "src/gql";
import { GroupDetailsInfoQuery, Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const AccordionControl = ({ props, rightSection }: { props: AccordionControlProps; rightSection: ReactNode }) => {
    return (
        <Center>
            <Accordion.Control {...props} />
            {rightSection}
        </Center>
    );
};

const AddMembersModal = ({
    initialMembers,
    groupId,
    opened,
    onClose,
}: {
    opened: boolean;
    onClose: () => void;
    initialMembers: string[];
    groupId: string;
}) => {
    const router = useRouter();
    const [newMembers, setNewMembers] = useState<string[]>([]);

    const { mutate: addMembersMutation } = useGraphqlMutation({
        document: addMembersToGroup,
        onSuccess: () => {
            notify.show.success({ message: "Added users to group" });
            void router.invalidate();
        },
        onError: () => notify.show.error({ message: "Error adding users to group" }),
    });

    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(usersQuery);
    const members = pages.flat();

    return (
        <Modal
            opened={opened}
            onClose={() => {
                onClose();
                setNewMembers([]);
            }}
            title={<Title order={3}>Add members</Title>}
            centered
        >
            {/* TODO: Search */}
            <Stack>
                <Stack mah="50dvh" style={{ overflowY: "auto" }}>
                    {members.map((m) => {
                        const checked = newMembers.includes(m.id) || initialMembers.includes(m.id);
                        return (
                            <Checkbox
                                key={m.id}
                                label={m.firstName || m.lastName ? `${m.firstName ?? ""} ${m.lastName ?? ""}` : "Unknown user"}
                                description="placeholder@email.com"
                                size="md"
                                checked={checked}
                                disabled={initialMembers.includes(m.id)}
                                onChange={() => setNewMembers(checked ? newMembers.filter((id) => id != m.id) : newMembers.concat(m.id))}
                            />
                        );
                    })}
                    <LoadMoreTrigger query={usersQuery} />
                </Stack>
                <Divider />
                <Button
                    w="100%"
                    onClick={() => {
                        addMembersMutation({ groupId, userIds: newMembers });
                        onClose();
                    }}
                >
                    Add
                </Button>
            </Stack>
        </Modal>
    );
};

const MemberDetails = ({ members, groupId }: { members: GroupDetailsInfoQuery["group"]["members"]; groupId: string }) => {
    const router = useRouter();
    const [opened, { open, close }] = useDisclosure(false);

    const { mutate: removeMemberMutation } = useGraphqlMutation({
        document: removeMembersFromGroup,
        onSuccess: () => {
            notify.show.success({ message: "Removed user from group" });
            void router.invalidate();
        },
        onError: () => notify.show.error({ message: "Failed to removed user from group" }),
    });

    const { checkPermission } = useUserStore();
    const canViewMembership = checkPermission(Permission.GroupUsersView, groupId);
    const canChangeMembership = checkPermission(Permission.GroupUsersManage, groupId);

    return (
        <>
            <AddMembersModal opened={opened} onClose={close} initialMembers={members.map((m) => m.id)} groupId={groupId} />
            <Accordion.Item value="members" style={{ borderBottom: "none" }}>
                <AccordionControl
                    rightSection={
                        <Tooltip label="You do not have permission to add members to this group" disabled={canChangeMembership}>
                            <Button
                                variant="outline"
                                color="var(--mantine-color-text)"
                                leftSection={<IconPlus />}
                                flex="1 0 auto"
                                onClick={open}
                                disabled={!canChangeMembership}
                            >
                                Add
                            </Button>
                        </Tooltip>
                    }
                    props={{ children: <Title order={3}>Members</Title> }}
                />
                <Accordion.Panel>
                    <Box style={{ flexGrow: 1 }}>
                        <Stack style={{ overflowY: "auto" }}>
                            {canViewMembership && members.length > 0 ? (
                                members.map((member) => (
                                    <Group key={member.id} justify="space-between">
                                        <Text>
                                            {member.firstName || member.lastName
                                                ? `${member.firstName ?? ""} ${member.lastName ?? ""}`
                                                : "Unknown user"}
                                        </Text>
                                        <ActionIcon
                                            variant="transparent"
                                            color="var(--mantine-color-text)"
                                            size="sm"
                                            disabled={!canChangeMembership}
                                            onClick={() => removeMemberMutation({ userId: member.id, groupId: groupId })}
                                        >
                                            <IconX />
                                        </ActionIcon>
                                    </Group>
                                ))
                            ) : (
                                <Stack align="center">
                                    <Text c="dimmed">No members found</Text>
                                </Stack>
                            )}
                        </Stack>
                    </Box>
                </Accordion.Panel>
            </Accordion.Item>
        </>
    );
};

// const SenderDetails = ({ senders }: { senders: GroupDetailsQuery["group"]["senders"] }) => {
//     // TODO
// };

// const TemplateDetails = ({ templates }: { templates: GroupDetailsQuery["group"]["template"] }) => {
//     // TODO
// };

const GroupDetails = ({ group }: { group: GroupDetailsInfoQuery["group"] }) => {
    const hasRequestError = useRef(false);
    const [isEditing, setIsEditing] = useState(false);
    const form = useForm<GroupDetailsInfoQuery["group"]>({
        initialValues: {
            ...group,
        },
    });
    const { reset, getValues } = form;

    function onRequestError(error: string): void {
        hasRequestError.current = true;
        notify.show.error({ message: error });
    }

    const { mutate: updateGroupMutation } = useGraphqlMutation({
        document: updateGroup,
        onError: () => onRequestError("Error updating group"),
    });

    const { mutate: addParentMutation } = useGraphqlMutation({
        document: addParent,
        onError: () => onRequestError("Error adding parent group"),
    });

    const { mutate: removeParentMutation } = useGraphqlMutation({
        document: removeParent,
        onError: () => onRequestError("Error removing parent group"),
    });

    function handleSubmit(data: typeof form.values) {
        if (form.isDirty("name") || form.isDirty("internalJoinable")) {
            updateGroupMutation({ internalJoinable: data.internalJoinable, name: data.name, groupId: data.id });
        }
        if (form.isDirty("parents")) {
            const newParents = getValues().parents.filter((p) => !group.parents.some((g) => g.id == p.id));
            const removedParents = group.parents.filter((p) => !getValues().parents.some((g) => g.id == p.id));
            if (newParents.length) newParents.forEach((p) => addParentMutation({ groupId: group.id, parentId: p.id }));
            if (getValues().parents.length === 0) onRequestError("Group must have at least one parent");
            else if (removedParents.length) removedParents.forEach((p) => removeParentMutation({ groupId: group.id, parentId: p.id }));
        }

        if (!hasRequestError.current) {
            notify.show.success({ message: "Updated group details" });
            form.setInitialValues({
                ...getValues(),
            });
            reset();
        }
    }

    useEffect(() => {
        setIsEditing(false);
        form.setInitialValues({ ...group });
        reset();
    }, [group]);

    useBlocker({
        blockerFn: () => {
            if (window.confirm("This action will lose currently unsaved changes. Continue anyways?")) {
                reset();
                setIsEditing(false);
                return true;
            }
            return false;
        },
        condition: form.isDirty(),
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Accordion.Item value="group" style={{ borderBottom: "none" }}>
                <AccordionControl
                    rightSection={
                        isEditing ? (
                            <Group wrap="nowrap" pl="md">
                                <Button
                                    color="var(--mantine-color-text)"
                                    type="submit"
                                    disabled={!form.isDirty()}
                                    leftSection={<IconDownload />}
                                >
                                    Save
                                </Button>
                                <Button
                                    color="var(--mantine-color-text)"
                                    variant="outline"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsEditing(false);
                                        reset();
                                    }}
                                    type="button"
                                >
                                    Cancel
                                </Button>
                            </Group>
                        ) : (
                            <ActionIcon size="lg" c="var(--mantine-color-text" variant="transparent" onClick={() => setIsEditing(true)}>
                                <IconEdit />
                            </ActionIcon>
                        )
                    }
                    props={{ children: <Title order={3}>Details</Title> }}
                />
                <Accordion.Panel>
                    <TextInput
                        key={form.key("name")}
                        label="Group Name"
                        placeholder="Enter name"
                        disabled={!isEditing}
                        {...form.getInputProps("name")}
                    />
                    <Stack gap={0} pt="sm">
                        <InputLabel>Parent Group(s)</InputLabel>
                        <GroupTreeModal
                            values={form.getValues().parents}
                            onChange={(parents) => form.setValues({ parents })}
                            disabled={!isEditing || group.synchronized}
                            group={group}
                        />
                    </Stack>
                    <Switch
                        key={form.key("internalJoinable")}
                        label="Internally Joinable?"
                        py="sm"
                        size="lg"
                        disabled={group.synchronized || !isEditing}
                        {...form.getInputProps("internalJoinable", { type: "checkbox" })}
                    />
                    <Title order={3}>Sender Profile</Title>
                    <Group p="sm">Placeholder</Group>
                    <Title order={3}>Templates</Title>
                    <Group p="sm">Placeholder</Group>
                </Accordion.Panel>
            </Accordion.Item>
        </form>
    );
};

const GroupDetailsContainer = () => {
    const { group } = Route.useLoaderData();
    const isMobile = useIsMobile();

    return (
        <Stack p={!isMobile ? "1rem" : undefined} h="100%" mah="85dvh" style={{ flexGrow: 1, overflowY: "auto" }}>
            <Accordion chevronPosition="left" w="100%" multiple defaultValue={["group", "members"]}>
                <GroupDetails group={group} />
                <MemberDetails members={group.members} groupId={group.id} />
            </Accordion>
        </Stack>
    );
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/groups/$groupId")({
    loader: async ({ abortController, params: { groupId } }) => {
        await queryClient.prefetchInfiniteQuery(usersQuery);
        const data = await httpPostGraphql(GroupInfoQuery, { groupId }, { signal: abortController.signal });
        return { group: data?.group };
    },
    component: GroupDetailsContainer,
});

const GroupInfoQuery = graphql(`
    query GroupDetailsInfo($groupId: UUID!) {
        group(id: $groupId) {
            id
            name
            internalJoinable
            synchronized
            children {
                id
                name
                internalJoinable
                synchronized
                members {
                    id
                    firstName
                    lastName
                }
            }
            members {
                firstName
                lastName
                id
            }
            parents {
                name
                id
            }
        }
    }
`);

const updateGroup = graphql(`
    mutation GroupDetailsUpdate($groupId: UUID!, $internalJoinable: Boolean!, $name: String!) {
        updateGroup(id: $groupId, params: { internalJoinable: $internalJoinable, name: $name }) {
            id
        }
    }
`);

const addParent = graphql(`
    mutation GroupDetailsAddParent($groupId: UUID!, $parentId: UUID!) {
        addParentToGroup(groupId: $groupId, parentId: $parentId) {
            id
        }
    }
`);

const removeParent = graphql(`
    mutation GroupDetailsRemoveParent($groupId: UUID!, $parentId: UUID!) {
        removeParentFromGroup(groupId: $groupId, parentId: $parentId) {
            id
        }
    }
`);

const usersQuery = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query GroupDetailsUsers($offset: Int!, $limit: Int!) {
            users(limit: $limit, offset: $offset) {
                id
                firstName
                lastName
            }
        }
    `),
    select: ({ users }) => users,
    queryKey: ["messages", "draft"],
});

const addMembersToGroup = graphql(`
    mutation GroupDetailsAddUsersToGroup($groupId: UUID!, $userIds: [UUID!]!) {
        addUsersToGroup(groupId: $groupId, userIds: $userIds) {
            id
        }
    }
`);

const removeMembersFromGroup = graphql(`
    mutation GroupDetailsRemoveMember($userId: UUID!, $groupId: UUID!) {
        removeUserFromGroup(userId: $userId, groupId: $groupId) {
            id
        }
    }
`);
