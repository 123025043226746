import { ActionIcon, AppShell, Box, Burger, Group, rem, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUserCircle } from "@tabler/icons-react";
import { createFileRoute, Link, Outlet, redirect, useRouterState } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { NavbarFooter, Sidebar } from "shared/components/global/Sidebar/Sidebar";
import { sidebarSubLinkData } from "shared/components/global/Sidebar/sidebar.config";
import { useColorMode } from "shared/hooks/useColorMode";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { useUserStore } from "shared/stores/oidc";
import { qk } from "shared/utils/qk";
import { BryxLogo } from "src/assets/BryxLogo";
import { graphql } from "src/gql";
import { queryClient } from "src/queryClient";

const AuthLayout = () => {
    const logoWidth = "30px";
    const logo = import.meta.env.VITE_ORG_LOGO_SOURCE;
    const title = import.meta.env.VITE_ORG_TITLE;

    const [navIndex, setNavIndex] = useState(0);
    const hideExpandPanel = sidebarSubLinkData[navIndex].data.length === 0;
    const isMobile = useIsMobile();
    const [opened, { toggle, open }] = useDisclosure(true);
    const { isDark } = useColorMode();
    const router = useRouterState();
    const theme = useMantineTheme();

    useEffect(() => {
        // On page load, find the tab we're on
        const maybeSubLink = sidebarSubLinkData.findIndex((link) => link.data.some((v) => router.location.pathname.includes(v.to)));
        if (maybeSubLink !== -1) setNavIndex(maybeSubLink);
    }, []);

    useEffect(() => {
        if (opened && hideExpandPanel) {
            toggle();
        }
        if (!hideExpandPanel) open();
    }, [navIndex, hideExpandPanel]);

    return (
        <AppShell
            header={{ height: "8dvh" }}
            footer={{ height: isMobile ? "8dvh" : 0 }}
            navbar={{ width: opened ? rem(225) : rem(50), breakpoint: theme.breakpoints.sm, collapsed: { mobile: !opened } }}
        >
            <AppShell.Header>
                <Group h="100%" px="md" justify="space-between">
                    {!hideExpandPanel && <Burger opened={opened} onClick={toggle} display={!isMobile ? "none" : undefined} size="sm" />}
                    <Group pl="xs">
                        <Group>
                            {Boolean(logo) ? <img src={logo} width={logoWidth} /> : <BryxLogo width={logoWidth} />}
                            <Title order={2} c="var(--mantine-primary-color-filled)" fw={"light"}>
                                {Boolean(title) ? title : "EMNS"}
                            </Title>
                        </Group>
                    </Group>
                    <Link to="/profile">
                        <Tooltip label="Profile" position="bottom" transitionProps={{ duration: 0 }} color={isDark ? "gray" : undefined}>
                            <ActionIcon variant="filled" aria-label="Profile" radius="lg" size="lg">
                                <IconUserCircle size={20} stroke={1.5} />
                            </ActionIcon>
                        </Tooltip>
                    </Link>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar>
                <Sidebar opened={opened} toggle={toggle} navIndex={navIndex} setNavIndex={setNavIndex} />
            </AppShell.Navbar>
            <AppShell.Main
                style={{
                    height: isMobile ? "84dvh" : "92dvh",
                    maxHeight: isMobile ? "84dvh" : "92dvh",
                    background: isDark ? theme.colors.dark[6] : theme.colors.gray[2],
                }}
            >
                <Box w="100%" h="100%" style={{ overflowY: "auto" }}>
                    <Outlet />
                </Box>
            </AppShell.Main>
            <AppShell.Footer h="fit-content" display={!isMobile ? "none" : undefined}>
                <NavbarFooter setNavIndex={setNavIndex} />
            </AppShell.Footer>
        </AppShell>
    );
};

/** @public */
export const Route = createFileRoute("/_auth")({
    beforeLoad: async ({ context, location }) => {
        const auth = await context.auth;
        const id: string = import.meta.env["VITE_USER_ID_OVERRIDE"] ?? auth.user?.profile.sub!;
        const userState = useUserStore.getState();

        // Support people will not be associated with a user
        if (auth.user?.profile.sub && userState.user.id == "") {
            const { user } = await queryClient.fetchQuery({
                queryKey: qk("user", "id", id),
                staleTime: 5 * 60 * 60, // 5 minutes
                queryFn: async () =>
                    httpPostGraphql(
                        graphql(`
                            query User($id: UUID!) {
                                user(userId: $id) {
                                    firstName
                                    id
                                    lastName
                                    groups {
                                        id
                                        name
                                    }
                                    roles {
                                        group {
                                            id
                                        }
                                        topic {
                                            id
                                        }
                                        role {
                                            name
                                            id
                                            type
                                            permissions {
                                                id
                                                name
                                                type
                                            }
                                        }
                                    }
                                }
                            }
                        `),
                        { id: import.meta.env["VITE_USER_ID_OVERRIDE"] ?? auth.user?.profile.sub! },
                    ),
            });

            userState.setUser(user);
        }

        if (!auth.isAuthenticated) throw redirect({ to: "/login", search: { redirect: location.href } });
    },
    component: AuthLayout,
});
